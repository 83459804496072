/*----------------------------------------------
*
* [Main Stylesheet]
*
* Theme    : Netstorm
* Version  : 1.0
* Author   : Themeland
* Support  : hridoy1272@gmail.com
*
----------------------------------------------*/

/*----------------------------------------------
*
* [Font Import]
*
* Jost    : https://fonts.google.com
* Poppins : https://fonts.google.com
*
----------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

/*----------------------------------------------

[ALL CONTENTS]

1. Import
2. Root
3. Reset
4. General
5. Section
6. Helper Class
7. Header
8. Feature
9. Footer
10. Modal
11. Miscellaneous
12. Blog
13. All Media Queries

----------------------------------------------*/


/*----------------------------------------------
1. Import
----------------------------------------------*/

/* #region Import */

@import './assets/css/vendor/bootstrap.min.css';
@import './assets/css/vendor/animate.min.css';
@import './assets/css/vendor/icons.min.css';
@import './assets/css/vendor/slider.min.css';
// @import './assets/css/vendor/all.min.css';
// @import '@fortawesome/fontawesome-free/scss/fontawesome';
// @import '@fortawesome/fontawesome-free/scss/regular';
// @import '@fortawesome/fontawesome-free/scss/brands';
// @import '@fortawesome/fontawesome-free/scss/solid';

@font-face {
  font-family: 'icons';
  src: url('./assets/fonts/Icons-Line.eot?v=2.4.0');
  src: url('./assets/fonts/Icons-Line.eot?v=2.4.0#iefix') format('embedded-opentype'), url('./assets/fonts/Icons-Line.woff2?v=2.4.0') format('woff2'), url('./assets/fonts/Icons-Line.ttf?v=2.4.0') format('truetype'), url('./assets/fonts/Icons-Line.woff?v=2.4.0') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* #endregion Import */

/*----------------------------------------------
2. Root
----------------------------------------------*/

/* #region Root */

:root {
  --primary-font: 'Jost', sans-serif;
  --secondary-font: 'Poppins', sans-serif;

  --h1-size: 5rem;
  --h1-weight: 900;

  --h2-size: 3rem;
  --h2-weight: 700;

  --h3-size: 2rem;
  --h3-weight: 600;

  --h4-size: 1.5rem;
  --h4-weight: 600;

  --h5-size: 1.2rem;
  --h5-weight: 600;

  --h6-size: 1rem;
  --h6-weight: 600;

  --p-size: 1rem;
  --p-weight: 400;

  @media screen and (max-width:767px) {
    --h1-size: 5rem;
    --h2-size: 3rem;
    --h3-size: 2rem;
    --h4-size: 1.5rem;
    --h5-size: 1.2rem;
    --h6-size: 1rem;
    --p-size: 1rem;
  }

  --header-bg-color: #0E0E28;
  --nav-brand-height-sm: 32px;
  --nav-brand-height-md: 42px;
  --nav-brand-height: 55px;
  --top-nav-item-color: #f5f5f5;
  --nav-item-color: #121117;

  // --primary-color: #4528DC;
  // --secondary-color: #57048A;

  --primary-color: rgb(58, 44, 195);
  --secondary-color: rgb(236, 101, 204);
  --tertiary-color: #00CC9B; // Godwoken green
  --tertiary-color-deep: #009C6B; // Godwoken green darker
  --tertiary-color-warn: #feac1d; // Godwoken warn
  --tertiary-color-warn-deep: #c37c00; // Godwoken warn

  --white-color: #f9f9f9;
  --black-color: #040402;
  --grey-color: #f3f5f7;
  --mid-grey-color: #A3A3A3;
  --dark-grey-color: #191919;

  // --primary-t-color: #f5f5f5;
  --primary-t-color: #09080D;

  --secondary-t-color: #f0f0f0;
  // --primary-p-color: #8E8E8E;
  --primary-p-color: #rgb(243, 243, 243);
  --secondary-p-color: #E1E1E1;
  --primary-b-color: #f5f5f5;
  --primary-l-color: rgba(0, 0, 0, .12);
  --secondary-l-color: rgba(255, 255, 255, 0.12);

  --valid-color: #007a4d;
  --invalid-color: #e34f4f;

  // --primary-bg-color: #09080D;
  --primary-bg-color: #eee;
  --primary-bg-color-2: #eeeeee;
  --primary-bg-color-3: #e5e5e5;

  --secondary-bg-color: #040402;
  --secondary-bg-color-2: #111111;
  --secondary-bg-color-3: #191919;

  // --card-bg-color: #16151A;
  --card-bg-color: #fcfcfc;
  --footer-bg-color: #0E0E28;
}

/* #endregion Root */

/*----------------------------------------------
3. Reset
HTML5 display-role reset for older browsers
----------------------------------------------*/

/* #region Reset */

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  font-family: var(--secondary-font);
  color: var(--primary-p-color);
  background-color: var(--primary-bg-color);
}

body.odd {
  background-color: var(--secondary-bg-color);
}

body:not(.page-template) .odd:not(.comment) {
  color: #8E8E8E;
}

body.page-template .odd {
  color: var(--white-color);
}

ol,
ul {
  padding-left: 20px;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

ul ul {
  list-style: circle;
}

pre,
code {
  display: block;
  margin: 1rem 0;
  color: var(--primary-color);
}

blockquote,
q {
  display: block;
  margin: 1rem 0;
  quotes: none;
  quotes: none;
  font-style: italic;
  padding: 1rem 1.5rem;
  border-left: 5px solid var(--primary-bg-color-3);
}

.odd blockquote,
q {
  border-left: 5px solid var(--secondary-bg-color-3);
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

blockquote p,
q p {
  margin: 0;
}

figure {
  margin-bottom: 1.5rem;
}

figcaption {
  margin-bottom: 1.5rem;
  font-style: italic;
  padding: 1rem 1.5rem;
  border-left: 5px solid var(--primary-bg-color-3);
}

.odd figcaption {
  border-left: 5px solid var(--secondary-bg-color-3);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* #endregion Reset */

/*----------------------------------------------
4. General
----------------------------------------------*/

/* #region General */
*,
*:after,
*:before {
  box-sizing: border-box;
}

a:not(.btn),
a:link:not(.btn),
a:visited:not(.btn),
a:hover:not(.btn),
a:active:not(.btn) {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1; // 1.5;
  color: var(--primary-color);
  text-decoration: none;
}

a:not([href]),
a:not([href]):hover,
a:not([href]) i,
a:not([href]) i:hover {
  color: var(--primary-b-color);
}

p {
  margin: 1.5rem 0;
  font-size: var(--p-size);
  font-weight: var(--p-weight);
  line-height: 1.5;
  -ms-word-wrap: break-word;
  word-wrap: break-word;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.odd:not(.comment) p {
  color: var(--white-color);
}

.page-template .odd p {
  opacity: 0.7;
}

.swiper-wrapper.odd p {
  opacity: 0.8;
}

h1 {
  margin-bottom: 1.5rem;
  font-size: var(--h1-size);
  font-weight: var(--h1-weight);
  line-height: 1.2;
  font-family: var(--primary-font);
  -ms-word-wrap: break-word;
  word-wrap: break-word;

  em {
    display: block;
    font-size: var(--h2-size);
    font-weight: 300;
  }
}

h2 {
  margin: 1.5rem 0;
  font-size: var(--h2-size);
  font-weight: var(--h2-weight);
  line-height: 1.2;
  font-family: var(--primary-font);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.odd h2 {
  color: var(--white-color);
}

h3 {
  margin: 1.5rem 0;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.2;
  font-family: var(--primary-font);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h4 {
  margin: 1.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--primary-font);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.odd h4 {
  color: var(--secondary-t-color);
}

h5 {
  margin: 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-t-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h6 {
  margin: 1.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--primary-t-color);
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

b,
strong {
  font-weight: 700;
}

li {
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

::placeholder {
  color: var(--primary-p-color) !important;
}

table {
  border-collapse: separate;
  border-spacing: 10px;

  td {
    padding: 10px 0;
  }

  th {
    padding: 10px 0;
  }
}

dt {
  margin: 15px 0;
}

address {
  margin-bottom: 1rem;
}

form {
  width: 100%;

  label {
    width: 100%;
  }
}

input,
textarea,
select {
  width: 100%;
  background: no-repeat;
  box-shadow: none;
  padding: 0.5rem 0.75rem;
  border-color: var(--primary-l-color);
  border-width: 1px;
  border-radius: 6px;
  background-color: var(--grey-color);

  &:focus {
    background: no-repeat;
    color: inherit;
    outline: none;
    border-color: var(--primary-t-color);
    box-shadow: none;
    background: #fff;
  }
}

input {
  border-color: var(--primary-p-color);
  color: var(--primary-p-color);
  border-radius: 10px;

  &[type="radio"] {
    height: initial;
  }

  &[type="submit"] {
    border: 1px solid;
    padding: 2.5px 10px;
    display: block;
    width: fit-content;
    max-width: 200px;
    text-align: center;
    color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
      color: var(--primary-b-color);
      border-color: var(--primary-color);
    }
  }

  &[type=checkbox] {
    width: 20px;
    height: 20px;
  }
}

textarea {
  height: 100px;
  resize: none;
  color: var(--primary-t-color);

  &:focus {
    border-color: var(--primary-t-color);
    color: var(--primary-t-color);
  }
}

select {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  line-height: 1.2;
  border-color: var(--primary-l-color);
  color: var(--primary-t-color);

  option {
    color: #2f323a;
  }

  &:focus {
    border-color: var(--primary-t-color);
    color: var(--primary-t-color);
  }
}

hr {
  margin: 35px 0;
  border-top: 1px dashed var(--primary-l-color);
}

.odd hr {
  margin: 35px 15px;
  border-top: 1px dashed var(--secondary-l-color);
}

table {
  width: 100%;
}

tr:not(:last-child) {
  border-bottom: 1px solid;
  border-color: var(--primary-l-color);
}

.odd tr:not(:last-child) {
  border-bottom: 1px solid;
  border-color: var(--secondary-l-color);
}

td {
  padding: 10px 0;
}

[disabled] {
  background: #1c1c1c !important;
  color: #d1d1d1 !important;
  cursor: not-allowed !important;
}


/* #endregion General */

/*----------------------------------------------
5. Section
----------------------------------------------*/

/* #region Section */

section {
  position: relative;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &.no-hero {
    padding: 150px 0 130px;
  }

  &#slider:not(.bg-inherit) {
    background-color: var(--hero-bg-color);
  }
}

main {
  padding: 0;
}

aside {
  padding: 0;
}

.container {
  &.header {
    max-width: 1140px;
  }

  &.full {
    max-width: 100%;
  }

  &.full-grid {
    padding: 0;
    max-width: calc(100% - 30px);
    margin: 0 15px;
  }

  &.smaller {
    max-width: 970px;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.vh-100 {
  height: 100vh;
}

.vh-75 {
  height: 75vh;
}

.clearfix {
  display: block;
  content: "";
  clear: both;
}


/* Row helpers, add margin between wrapped columns */
.row.row-grid [class*="col-"] + [class*="col-"] {
  margin-top: 30px;
}

@media (min-width: 1200px) {
  .row.row-grid [class*="col-lg-"] + [class*="col-lg-"] {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .row.row-grid [class*="col-md-"] + [class*="col-md-"] {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .row.row-grid [class*="col-sm-"] + [class*="col-sm-"] {
    margin-top: 0;
  }
}




/* #endregion Section */

/*----------------------------------------------
6. Helper Class
----------------------------------------------*/

/* #region Helper Class */

.netstorm-preloader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  z-index: 99999;

  .preloader-animation {
    z-index: 1000;

    .spinner {
      height: 9em;
      width: 9em;
      margin: 0 auto 3.5em auto;
      border: 3px solid var(--primary-l-color);
      border-top-color: var(--footer-bg-color);
      border-radius: 50%;
      animation: spinner 1s infinite linear;
    }

    p {
      letter-spacing: 8px;
    }
  }

  .loader-animation {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    pointer-events: none;

    .single-loader {
      .loader-bg {
        height: 100%;
        width: 100%;
        left: 0;
        background-color: var(--white-color);
        transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
      }
    }
  }

  &.loaded {
    visibility: hidden;

    .preloader-animation {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
    }

    .single-loader {
      .loader-bg {
        width: 0;
        transition: 0.7s all cubic-bezier(0.1, 0.1, 0.1, 1);
      }
    }
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes animated-letters {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
  min-height: 3rem;
  min-width: 3rem;
}

.avatar-md {
  height: 4rem;
  width: 4rem;
  min-height: 4rem;
  min-width: 4rem;
}

.avatar-lg {
  height: 7rem;
  width: 7rem;
  min-height: 7rem;
  min-width: 7rem;
}

.intro {
  position: relative;
  z-index: 1;
  margin: 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (min-width: 967px) {
    justify-content: space-between;
  }

  span {
    @extend .text-effect;
    position: relative;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;

    &::after {
      position: absolute;
      content: '';
      height: 3px;
      width: 45px;
      background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
      top: 50%;
      left: calc(100% + 20px);
      transform: translateY(-50%);
    }
  }

  h1,
  h2,
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }

  &.text-center {
    span {
      &::after {
        content: none;
      }
    }
  }
}

.list-group-item {
  margin: 0;
  background: none;
  font-size: 1rem;
  padding-right: 0;
  padding-left: 0;
  background-color: transparent;
  border-color: var(--card-bg-color);

  &:first-child {
    border: none;
  }

  a {
    transition: 0.3s;
  }

  .badge {
    &.circle {
      background-color: var(--card-bg-color);
    }
  }
}

.post-holder {
  list-style: none;
  width: 100%;
  border-left: 5px solid var(--primary-color);
  background-color: var(--card-bg-color);
  padding: 25px;
  margin-top: 35px;
}

.posted-on a:not(.btn) {
  font-weight: 600;
}

.sidebar {
  .item {
    margin-bottom: 35px;
  }

  ol {
    list-style: none;
    padding: 0;

    ol {
      padding: 15px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    &.children {
      padding: 0;
    }

    li {
      &:not(.nav-item) {
        &:not(.list-group-item) {
          position: relative;
          padding-left: 0;
        }
      }

      a {
        &:not(.btn) {
          color: inherit;
          overflow-wrap: break-word;

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    ul {
      padding: 15px;

      li {
        &:not(.nav-item) {
          &:not(.list-group-item) {
            &:not(.cat-item) {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }

  li {
    &:hover {
      &::before {
        background-color: var(--primary-color);
      }
    }
  }

  .sub-menu {
    padding: 0;
  }

  .title {
    width: 100%;
    margin-top: 0;
    list-style: none;
  }
}

.list-group {
  .list-group-item {
    .icon {
      color: var(--primary-color);
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.post-details {
  .blog-thumb {
    img {
      border-radius: 4px;
    }
  }

  blockquote {
    margin: 1rem 2rem;
    padding: 2rem 1.5rem;
    border-left: 5px solid var(--primary-color);
    font-size: 1.2rem;
    color: var(--primary-color);
    background-color: rgba(1, 114, 128, 0.05);

    @media(max-width:575px) {
      font-size: 100%;
    }
  }

  .sidebar {
    .title {
      position: relative;
      font-weight: 700;

      &::before {
        position: absolute;
        content: '';
        height: 3px;
        width: 50px;
        background-color: var(--primary-color);
        left: 3px;
        top: calc(100% + 10px);
      }
    }
  }
}

.btn {
  --btn-border-radius: 12px;

  &.branded {
    @extend .bg-text;
    color: #fff;
  }

  position: relative;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  padding: 12px 34px;
  border-radius: var(--btn-border-radius);
  outline: 0 none;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s ease-in-out,
  background-color 0.3s ease-in-out,
  top 0.2s ease-in;
  font-weight: 400;

  &:hover,
  &:focus,
  &:active {
    color: var(--white-color);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }

  &.btn-primary {
    background: var(--primary-color);
    border: 1px solid var(--secondary-color);
  }

  &.btn-warn {
    background: #a31616;
    border: 1px solid black;
    color: #fff;

    &:hover {
      background: var(--invalid-color);
    }
  }

  &.btn-text {
    padding: 0;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-style: italic;
    border-bottom: 1px solid var(--primary-color);
    border-radius: 0;

    &:hover {
      box-shadow: none;
      border-bottom-color: var(--secondary-color);
    }
  }

  &.btn-bordered {
    background: transparent none repeat scroll 0 0;
    // background-color: white;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    position: relative;
    top: 0;

    &:hover {
      border: 1px solid var(--white-color);
      background-color: var(--primary-color);
      color: var(--white-color);
    }

    // &::before {
    //   position: absolute;
    //   content: "";
    //   height: calc(100% + 1px);
    //   width: calc(100% + 1px);
    //   background-image: linear-gradient(115deg, var(--primary-color) 10%, var(--secondary-color));
    //   top: 3px;
    //   left: -1px;
    //   border-radius: var(--btn-border-radius);
    //   transition: all 0.25s ease 0s;
    //   z-index: -2;
    // }

    // &:after {
    //   position: absolute;
    //   content: "";
    //   height: 100%;
    //   width: 100%;
    //   top: 1px;
    //   left: 0;
    //   border-radius: var(--btn-border-radius);
    //   background: #fff none repeat scroll 0 0;
    //   transition: all 0.3s ease 0s;
    //   z-index: -1;
    // }

    &:hover,
    &:focus,
    &.active {
      border: 1px solid var(--white-color);
      // color: var(--white-color);
      background-color: var(--primary-color);
      top: 2px;

      &:after {
        opacity: 1;
        top: 1px;
        left: 0px;
        border-radius: calc(var(--btn-border-radius) - 1px);
      }

      &:before {
        height: calc(100% - 1px);
        width: calc(100% + 2px);
        border-radius: calc(var(--btn-border-radius) - 1px);
      }
    }
  }

  &.btn-bordered-white {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #fff;
    font-weight: 400;

    // &::after {
    //     position: absolute;
    //     content: "";
    //     height: calc(100% + 4px);
    //     width: calc(100% + 4px);
    //     background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
    //     top: -2px;
    //     left: -2px;
    //     border-radius: var(--btn-border-radius);;
    //     opacity: 0;
    //     transition: all 0.3s ease 0s;
    //     z-index: -1;
    // }
    &:hover,
    &:focus {
      border-color: transparent;
      outline: 0 none;

      &::after {
        opacity: 1;
      }
    }
  }

  &.btn-solid-green,
  &.btn-solid-warn {
    background: var(--tertiary-color);
    border: none;
    font-weight: 700;
    color: var(--black-color);
    padding: 24px 48px;

    &::after {
      position: absolute;
      content: "";
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      background-image: linear-gradient(35deg, var(--tertiary-color-deep) 10%, var(--tertiary-color));
      top: -2px;
      left: -2px;
      border-radius: var(--btn-border-radius);
      ;
      opacity: 0;
      transition: all 0.3s ease 0s;
      z-index: -1;
    }

    &:hover,
    &:focus {
      border-color: transparent;
      outline: 0 none;

      &::after {
        opacity: 1;
      }
    }
  }

  &.btn-solid-warn {
    background: var(--tertiary-color-warn);

    &::after {
      background-image: linear-gradient(35deg, var(--tertiary-color-warn-deep) 10%, var(--tertiary-color-warn));
    }
  }

  &.content-btn {
    font-weight: 600;
    color: var(--primary-t-color);
    background: transparent;
    box-shadow: none;
    padding: 0;

    & svg {
      position: relative;
      right: 0px;
      transition: right 250ms ease-in-out;
    }

    &:hover {
      color: var(--primary-color);

      & svg {
        right: -0.25rem;
      }
    }
  }

  &.loading {
    color: transparent;

    &::before {
      content: "";
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-top-color: #ffffff;
      border-radius: 50%;
      animation: button-loading-spinner 650ms linear infinite;
    }
  }
}

.btn-lg {
  font-size: 1.4rem;
}

.btn-caps {
  text-transform: uppercase;
}

.button-group {
  margin-top: 30px;

  a {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 575px) {
      margin-top: 0;
      margin-right: 5px;

      .btn {
        padding: 12px 20px;
      }
    }
  }
}

.shape {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  line-height: 0;
  direction: ltr;
  overflow: hidden;
  z-index: -1;

  &.shape-bottom {
    svg {
      display: block;
      height: 275px;
      width: calc(228% + 1.3px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.bg-shape {
  position: absolute;
  height: 60%;
  width: 55%;
  top: 35%;
  right: -5%;
  transform: translateY(-50%);
  z-index: -1;

  @media(max-width: 767px) {
    height: 80%;
    width: 80%;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  @media(max-width: 575px) {
    height: 100%;
    width: 100%;
    top: 70%;
  }
}

.shape-bottom {
  position: absolute;
  top: auto;
  bottom: -1px;
  left: 0;
  right: 0;
  z-index: -1;
}

.bg-grey {
  background-color: var(--grey-color);

  input,
  textarea {
    background-color: var(--white-color);
  }
}

.bg-dark {
  background-color: #121212 !important;
}

/* #endregion Helper Class */

/*----------------------------------------------
7. Header
----------------------------------------------*/

/* #region Header */
.navbar-holder {
  position: relative;
  z-index: -100;
  min-height: auto;
  pointer-events: none;
  background-color: var(--section-1-bg-color);
}

.menu {
  .items {
    margin-left: -5px;
    width: calc(100% + 10px);
  }

  .navbar-nav {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;

    i {
      &.icon-arrow-down {
        font-size: 18px;
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      width: 100%;
      visibility: visible;
      opacity: 1;
      background: none;
      box-shadow: none;
      border: none;
      list-style: none;

      .dropdown-menu {
        position: relative;
        top: initial;
        left: initial;
      }

      .left,
      .right {
        width: 50%;
      }

      .nav-link {
        &.title {
          font-weight: 600;
          color: var(--primary-t-color);
          border: none;
          opacity: 0.8;
        }

        &:hover {
          color: var(--primary-color);
        }
      }

      .nav-item {
        .nav-link {
          font-weight: 400;
          max-width: max-content;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .nav-link {
    &:not(.btn) {
      font-size: 1.5rem;
      font-weight: 600;
      border: none;
      color: var(--primary-t-color);

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  .dropdown-menu {
    .dropdown {
      > .nav-link {
        &:first-child {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }
}

/* #endregion Header */

/*----------------------------------------------
8. Feature
----------------------------------------------*/

/* #region Feature */

.icon {
  max-width: 44px;
  max-height: 44px;
  line-height: 44px;
  // text-align: center;
  font-size: 44px;
  display: block;
  // margin: auto;

  &.smaller {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
  }

  &.bigger {
    width: 7rem;
    height: 7rem;
    margin: 0;
    font-size: 6.5rem;
    line-height: 7rem;
    font-weight: bold;
  }

  &.circle {
    border-radius: 100px;
    font-size: 28px;
  }
}

.icons {
  display: inline-block;
  font-size: 3rem;
  line-height: 1;
}

.clip-text,
.text-effect {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-text,
.text-effect,
.blog-details .pagination > li.active > a {
  background-image: linear-gradient(150deg, var(--secondary-color) 0%, var(--primary-color) 78%);
}

.card-columns {
  margin: 0 15px;
  column-gap: 30px;

  .card {
    margin-bottom: 30px;
  }
}

.card {
  padding: 16px;
  border: none;
  border-radius: 20px;
  background-color: var(--card-bg-color);
  box-shadow: 0 3px 20px 0px var(--primary-l-color);
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
  transition-duration: 0.3s;
  transition-property: opacity, transform;

  .card-body {
    // flex: 1 1 auto;
    min-height: 1px;
    // padding: 16px 0 0;

    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }

    h5 {
      font-weight: 600;
      font-size: 1rem;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-bottom {
      span {
        color: var(--black-color);
      }
    }
  }

  .card-footer {
    border-radius: 0;
    background: none;
    padding: 30px 0;
    margin: 0 30px;
    border-top: 1px solid var(--primary-l-color);

    a {
      margin: 0 10px;

      &:not(.btn) {
        color: var(--primary-p-color);
      }
    }

    .card-footer i {
      margin: 0 5px 2.5px;
    }
  }

  &:hover {
    .card-footer {
      color: #f5f5f5;
      border-color: rgba(255, 255, 255, 0.25);
    }
  }

  &.no-hover {
    border: none;
    box-shadow: none;
    border-radius: 14px;

    &:hover {
      transform: inherit;
      box-shadow: none !important;
    }
  }

  &:not(.no-hover) {
    &:hover {
      transform: translateY(-5px);
    }
  }

  &.blog-card {
    .blog-thumb {
      img {
        border-radius: 6px;
      }
    }

    .blog-content {
      a {
        color: var(--white-color);
        transition: 0.3s;

        h4 {
          transition: 0.3s;
        }

        &:hover {
          color: var(--primary-color);

          h4 {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  margin-top: 50px;
  position: relative;
}

.swiper-pagination-bullet {
  width: 40px !important;
  height: 5px !important;
  border-radius: 0 !important;
  background-color: var(--primary-p-color);
  border: none !important;
  opacity: 0.25 !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--primary-color);
  opacity: 1 !important;
}

.live-auctions-area {
  .card {
    .card-body {
      a {
        h5 {
          transition: 0.3s;
        }

        &:hover {
          h5 {
            color: var(--primary-color);
          }
        }
      }
    }

    .seller {
      span {
        // color: var(--black-color);
        transition: 0.3s;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}

.TopBuyers {
  .card {
    .single-seller {
      .seller-info {
        a {
          transition: 0.3s;
        }
      }
    }
  }
}

// TODO see where this is actually used
.load-more {
  position: relative;
  z-index: 12;
  margin-bottom: 1rem;
}

.seller-info {
  display: grid;
  align-content: center;

  .seller {
    font-weight: 600 !important;
    color: var(--black-color) !important;
    transition: 0.3s;

    &:hover {
      color: var(--primary-color) !important;
    }
  }
}

/* card info spacing and layout for seller and pricing */
.card,
.nft-card {

  .nft-info-owner,
  .nft-info {
    font-size: 1rem;
    text-wrap: balance;
  }

  .nft-sale-status {
    display: flex;
    align-items: center;

    &.not_started {
      // yellowy
      background-color: rgba(226, 164, 0, 0.95);
    }

    &.in_progress {
      // green
      background-color: rgba(46, 142, 75, 0.95);
    }

    &.ended {
      // black or red?
      background-color: rgba(100, 0, 0, 0.8);
    }
  }
}

.btn-smaller {
  font-size: 0.9rem;
  padding: 12px 18px;
}

.explore-area {
  .explore-menu {
    .btn {
      background: inherit;
      color: var(--black-color);
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      outline: none;
      position: relative;
      box-shadow: none;

      &::after {
        position: absolute;
        content: '';
        height: 2px;
        width: 0;
        bottom: 5px;
        left: 50%;
        background-color: var(--primary-color);
        transform: translateX(-50%);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease 0s;
      }

      &.active {
        color: var(--primary-color);

        &::after {
          width: 60%;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .card {
    .card-body {
      a {
        h5 {
          transition: 0.3s;
        }

        &:hover {
          h5 {
            color: var(--primary-color);
          }
        }
      }
    }

    .seller {
      a {
        h6 {
          transition: 0.3s;
        }

        &:hover {
          h6 {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

.activity-area {
  .netstorm-tab {
    &.nav-tabs {
      border-bottom: 1px solid var(--card-bg-color);
    }

    li {
      margin: 0;

      a {
        position: relative;
        display: inline-block;
        margin: 0 10px;
        padding: 10px 0;

        &::after {
          position: absolute;
          content: '';
          height: 1px;
          width: 0;
          bottom: -1px;
          left: 0;
          background-color: var(--primary-color);
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease 0s;
        }

        &.active {
          h5 {
            color: var(--primary-color);
          }

          &::after {
            width: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &:first-of-type {
        a {
          margin-left: 0;
        }
      }
    }
  }

  .tab-content {
    .single-tab-list {
      margin: 1.5rem 0;

      img {
        border-radius: 4px;
      }

      strong,
      a {
        color: var(--black-color);
        transition: 0.3s;
      }

      a {
        display: block;

        h5 {
          transition: 0.3s;
        }

        &:hover {
          color: var(--primary-color);

          h5 {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  .single-widget {
    .filter-widget {
      .title {
        position: relative;
        margin-top: 0;
        font-weight: 700;
        border: none;

        &::before {
          position: absolute;
          content: '';
          height: 3px;
          width: 50px;
          background-color: var(--primary-color);
          left: 3px;
          top: calc(100% + 10px);
        }
      }

      .filter-widget-items {
        a {
          &:hover {
            color: var(--primary-color) !important;
          }
        }
      }
    }
  }
}

.wallet-connect-area {
  .single-wallet {
    padding: 45px;

    h4 {
      transition: 0.3s;
    }

    p {
      color: var(--primary-p-color);
    }

    &:hover {
      h4 {
        color: var(--primary-color);
      }
    }
  }
}

.help-center-area {
  .card {
    padding: 45px;
    min-height: 300px;
    display: flex;
    justify-content: center;

    h4 {
      transition: 0.3s;
    }

    p {
      color: var(--primary-p-color);
    }

    &:hover {
      h4 {
        color: var(--primary-color);
      }
    }
  }
}

.faq-area {
  .card-header {
    .btn {
      position: relative;

      &::after {
        position: absolute;
        content: '\f107';
        top: 50%;
        left: calc(100% - 34px);
        transform: translateY(-50%);
        font-size: 18px;
        font-weight: 900;
        font-family: 'Font Awesome 5 Free';
      }

      &.collapsed {
        &::after {
          position: absolute;
          content: '\f105';
          top: 50%;
          left: calc(100% - 34px);
          transform: translateY(-50%);
          font-size: 18px;
          font-weight: 900;
          font-family: 'Font Awesome 5 Free';
        }
      }
    }
  }
}

.badge {
  font-weight: 500;

  &.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    background-color: var(--primary-bg-color-3);
  }

  &.tag {
    margin: 0.3rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 400;
    color: var(--mid-grey-color);
    border: 1px solid var(--mid-grey-color);
    transition: 0.3s;

    &.active {
      color: var(--primary-color);
      border-color: var(--primary-color);
    }

    &:hover {
      color: var(--primary-color) !important;
    }
  }
}

.share-list {
  display: -webkit-inline-box;

  .nav-link {
    i {
      @extend .text-effect;
      margin: 0 1rem;
      font-size: 22px;
    }
  }
}

.image-over {

  > a:link,
  a:visited,
  a:hover {
    color: transparent;
  }
}

.bg-overlay {
  position: relative;
  z-index: 0;

  &::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
    opacity: 0.94;
    z-index: -2;
  }
}

.overlay-dark {
  &::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--header-bg-color);
    opacity: 0.65;
    z-index: -1;
  }
}

.social-icons {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;

  @media(min-width: 767px) {
    justify-content: flex-start;
    gap: 1rem;
  }

  svg {
    display: block;
    height: 100%;
    line-height: 45px;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
  }

  > a {
    width: 45px;
    height: 45px;
    font-size: 20px;
    background: #222;
    color: var(--white-color) !important;
    border-radius: 3px;
    overflow: hidden;
    transition: all 0.3s ease 0s;

    &:hover {
      svg {
        &:first-child {
          margin-top: -45px;
        }
      }
    }
  }
}

// How it works area
.work-area {
  padding: 2rem 0;
  margin: 0 auto -1rem;
  position: relative;
  z-index: 12;
  color: #fff;
  background: transparent !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.90;
    background: url("./assets/img/work-area-bg.png");
    background-size: cover;
    background-position: right bottom;
  }

  & > div {
    position: relative;
    z-index: 2;
  }

  @media (min-width: 767px) {
    background-position: center;
    background-size: cover;
    color: #fff;
  }

  .items {
    display: grid;
    gap: 1rem;

    @media (min-width: 767px) {
      display: flex;
    }
  }

  & .create {
    color: var(--tertiary-color);
    transition: color 150ms ease-in;
    border: none;

    &:hover {
      color: color-mix(in srgb, var(--tertiary-color) 80%, var(--primary-color));
    }
  }

  .intro {
    span {
      // @extend .text-effect;
      color: var(--white-color);
      -webkit-text-fill-color: unset;
      background-image: none;
      position: relative;
      display: inline-block;
      font-weight: 600;
      text-transform: uppercase;

      &::after {
        position: absolute;
        content: '';
        height: 2px;
        width: 45px;
        background: var(--white-color);
        -webkit-text-fill-color: unset;
        // background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
        top: 50%;
        left: calc(100% + 10px);
        transform: translateY(-50%);
      }
    }

    h1,
    h2,
    h3 {
      color: var(--white-color);

      &:first-child {
        margin-top: 0;
      }
    }

    &.text-center {
      span {
        &::after {
          content: none;
        }
      }
    }
  }

  .single-work {
    border: 1px solid var(--white-color);
    border-radius: 12px; // TODO var for border radius?
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 200px;

    .icon {
      color: var(--white-color);
      margin: 0 0 1rem;
    }

    h4 {
      font-size: 1.1rem;
      margin: 0;
    }

    p {
      font-size: 0.8rem;
      margin-top: 1rem;
    }

    h4,
    p {
      color: var(--white-color);

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

/* #endregion Feature */




/*----------------------------------------------
9. Footer
----------------------------------------------*/

/* #region Footer */

footer {
  position: relative;
  z-index: 1;
  background-color: var(--footer-bg-color);
  color: var(--secondary-p-color);
  padding: 2rem 1rem 1rem;
  margin-top: 1rem;

  & .footer-area {
    display: grid;
    gap: 1.5rem;

    @media(min-width:767px) {
      grid-template-columns: 1fr 1fr;
    }

    .footer-title {
      margin-top: 0;
      color: var(--white-color);
    }

    .navbar-brand {
      padding: 0;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 0;
      color: var(--white-color);

      img {
        cursor: pointer;
      }
    }

    .social-icons {
      & > a {
        width: 40px;
        height: 40px;
        font-size: 18px;

        &:hover svg:first-child {
          margin-top: -40px;
        }
      }
    }

    .subscribe-form {
      position: relative;

      .form-control {
        height: 50px;
        border: none;
        outline: none;
        padding-left: 20px;
        border-radius: 6px;
        background-color: #191919;
        color: var(--secondary-p-color);

        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }

      input {
        font-size: 14px;
        text-indent: 3%;

        @media (max-width: 575px) {
          text-indent: 0;
        }

        &::placeholder {
          color: var(--primary-p-color);
        }
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 16px 25px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    ul {
      padding-left: 0;

      li {
        margin: 1rem 0 0;
        list-style: none;

        a:not(.btn) {
          color: var(--secondary-p-color);
        }
      }
    }

    & .tagline {
      margin: 1rem 0 0;

      & img {
        max-width: 300px;
      }
    }

    & .footer-links {
      justify-self: flex-end;

      @media(min-width:767px) {
        width: max-content;
      }
    }

    .copyright-area {
      grid-column: 1/-1;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      border-top: 1px solid rgba(255, 255, 255, 0.12);
      padding-top: 1rem;

      @media (min-width:767px) {
        justify-content: space-between;
      }

      a {
        color: rgba(255, 255, 255, 0.8);
      }

      & .powered-by {
        @media (max-width:766px) {
          max-width: 75%;
        }
      }
    }

    @media (max-width: 575px) {
      .col-12:last-of-type .footer-items {
        margin-bottom: 0;
      }
    }
  }
}

/* #endregion Footer */

/*----------------------------------------------
10. Modal
----------------------------------------------*/

/* #region Modal */

.modal-open {
  overflow-x: hidden;
  overflow-y: hidden;
}

.dialog-animated {
  min-height: 100%;
  margin: 0 0 0 auto;
}

.modal {
  &.fade {
    .modal-dialog {
      &.dialog-animated {
        transform: translate(100%, 0)scale(1);
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        height: 100%;
      }
    }

    &.show {
      .modal-dialog {
        &.dialog-animated {
          height: 100%;
          transform: translate(0, 0);
        }
      }
    }
  }
}

.modal-content {
  background-color: var(--primary-bg-color);
  border: none;
  border-radius: 0;
  overflow-x: hidden;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border: none;
  font-weight: 600;
  border-radius: 0;
  color: #000;

  .icon-close {
    font-size: 24px;
    cursor: pointer;
  }
}

.modal-body {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
}

.search-title {
  font-size: 45px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: -1.6px;
  color: var(--black-color);
}

.web3-modal {
  & .MuiPaper-root {
    margin: 0 auto;
  }

  & .content {
    padding: 2rem;
    text-align: center;
    max-width: 100%;
    background: #f0f8ff;

    & button {
      gap: 0.5rem;
    }
  }
}

/* #endregion Modal */

/*----------------------------------------------
11. Miscellaneous
----------------------------------------------*/

/* #region Miscellaneous */

.scroll-to-top {
  display: none;
  position: fixed;
  width: 40px;
  height: 40px;
  line-height: 45px;
  text-align: center;
  background-color: var(--card-bg-color);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  right: 1.75rem;
  bottom: 2.25rem;
  opacity: 0.85;
  z-index: 150;
  transition-timing-function: ease;
  transition-duration: .4s;
  transition-property: opacity, transform;

  a {
    display: block;
    height: 100%;
    width: 100%;
    line-height: 45px !important;

    svg {
      font-size: 22px;
      font-weight: 600;
      color: var(--black-color);
    }
  }

  &:hover {
    opacity: 1;
  }

  &:hover,
  &:focus {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    cursor: pointer;
  }
}

/* Labels */
.label {
  text-transform: uppercase;
  font-size: 0.6rem;
  color: var(--mid-grey-color);
}

/* Bridge wrapper */
.bridge-container {
  width: 100%;
  height: 100%;
  min-height: 620px;
  display: flex;
}

/* #endregion Miscellaneous */

/*----------------------------------------------
12. Blog
----------------------------------------------*/

/* #region Blog */

.single-widget {
  margin-bottom: 35px;

  h4 {
    font-weight: 700;
    border-bottom: 2px solid var(--card-bg-color);
  }
}

.sidebar .single-widget:last-child {
  margin-bottom: 0;
}

.blog {
  .col-12.col-sm-6 {
    @media(max-width: 575px) {
      padding: 0;
    }
  }

  .single-comments {
    @media (max-width: 575px) {
      flex-direction: column;
    }
  }

  .single-widget {
    ul {
      li {
        margin: 1.2rem 0;
      }
    }

    .tags-widget a {
      border: 1px solid var(--primary-bg-color-3);
    }

    .post-widget {
      h6 {
        font-size: var(--h5-size);
      }
    }
  }

  .pagination {
    > li {
      > a {
        display: inline-block;
        height: 45px;
        width: 45px;
        line-height: 45px;
        text-align: center;
        color: var(--primary-p-color);
        border: 1px solid var(--primary-bg-color-3);
      }

      &.active {
        > a {
          @extend .bg-text;
          color: var(--white-color);
          border: none;
        }
      }
    }
  }
}

/* #endregion Blog */

/*----------------------------------------------
13. All Media Queries
----------------------------------------------*/

/* #region All Media Queries */

@media(max-width: 993px) {

  /* #region Section */

  body {
    .container {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      max-width: 100%;
    }
  }

  /* #endregion Section */

  /* #region Header */

  body {
    header {
      .navbar-expand {
        .navbar-nav {
          &.toggle {
            display: flex;
          }

          &.contacts,
          &.items,
          &.icons .social {
            display: none;
          }
        }
      }
    }
  }

  /* #endregion Header */

  /* #region Feature */

  body {
    .card-footer {
      margin: 0;
    }
  }

  /* #endregion Feature */
}

@media(max-width: 991px) {

  /* #region General */

  body {
    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 2.5rem;
    }
  }

  /* #endregion General */

  /* #region Section */

  body {
    aside {
      margin-top: 60px;
    }
  }

  /* #endregion Section */


  /* #region Feature */

  body {
    .res-margin {
      margin-bottom: 30px;
    }
  }

  /* #endregion Feature */
}

@media(max-width: 767px) {

  /* #region General */

  body {
    h1 {
      font-size: 2.2rem;
    }

    h2 {}

    h3 {
      font-size: 1.8rem;
    }
  }

  /* #endregion General */

  /* #region Helper Class */

  body {
    .netstorm-preloader {
      .preloader-animation {
        .spinner {
          height: 8em;
          width: 8em;
        }

        .loader {
          font-size: var(--h2-size);
        }
      }
    }

    .intro {
      .btn {
        margin-top: 30px;
      }
    }
  }

  /* #endregion Helper Class

    /* #region Header */

  body {
    header {
      .navbar-expand {
        .navbar-brand {
          margin: 0;
        }
      }
    }
  }

  /* #endregion Header */

  /* #region Features */

  body {
    .icon {
      &.bigger {
        width: 5rem;
        height: 5rem;
        margin: 0;
        font-size: 4.5rem;
        line-height: 5rem;
        font-weight: bold;
      }
    }
  }

  /* #endregion Features */

  /* #region Footer */

  body {
    footer {
      .contacts {
        left: 0;
        margin-bottom: 30px;

        &:before {
          left: 0;
          width: 100%;
        }

        &:after {
          left: 0;
          width: 100%;
        }
      }
    }
  }

  /* #endregion Footer */
}

@media (max-width: 576px) {

  /* #region Helper Class */

  body {
    .netstorm-preloader {
      .preloader-animation {
        .spinner {
          height: 7em;
          width: 7em;
        }

        .loader {
          font-size: var(--h3-size);
        }
      }
    }
  }

  /* #endregion Helper Class */

  /* #region Modal */

  body {
    .modal-body {
      align-items: start;
    }
  }

  /* #endregion Modal */
}

.react-datepicker-popper {
  z-index: 3;
}

@import "./assets/css/Verification.scss";

/* #endregion All Media Queries */




/* cropper */
.ap-crop-container .reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
  width: 100% !important;
  ;
  height: 100% !important;
  ;
}

.ap-crop-container .reactEasyCrop_Image.reactEasyCrop_Contain {
  width: 100% !important;
  height: 100% !important;
  ;
}

.ap-cropped-image {
  max-height: 320px;
  width: 100%;
}

.ap-cropped-image-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

._coverImage-holder {
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 40px;
}


.ap-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.ap-crop-container {
  height: 100%;
  width: 100%;
}

.ap-controls {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: -60px;
}

.grid.items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 47%));
  gap: 1rem 3%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 12;
  padding: 0.5rem;
  background: #eeee;
  border-radius: 1rem;

  @media (min-width:767px) {
    display: flex;
    gap: 1rem;
  }

  &.activities {
    grid-template-columns: 1fr;
    gap: 0.75rem 3rem;

    .activity-content {
      order: -1;
    }

    @media (min-width: 768px) {
      grid-template-columns: 3fr 1.2fr;

      .activity-content {
        order: initial;
      }
    }

    .nav {
      grid-column: 1/3;
    }
  }

  .item {
    padding: 0;

    flex-grow: 1;
    flex-basis: 0;
    align-self: stretch;
    justify-self: center;
    min-width: 250px;
    // max-width: 300px;

    .card {
      position: relative;
      display: grid;
      gap: 0.75rem;
      height: 100%;
      max-height: 600px;
      padding-bottom: 1.5rem;
      // justify-items: center;

      > * {
        grid-column-start: 1;
      }

      & .asset-type {
        position: absolute;
        z-index: 5;
        top: 0.5rem;
        left: 0;
        background: #451CCEd9;
        height: 1.75rem;
        width: 1.75rem;
        text-align: center;
        border-radius: 0.5rem;
        border: 1px solid rgba(255, 255, 255, 0.6);
      }

      & .image-over {
        height: 100%;
        align-self: flex-start;
        grid-row: 1 / 3;
        height: 225px;

        display: grid;

        & img {
          height: 100%;
          width: 100%;
          height: 225px;
        }
      }

      & .image-link {
        height: 225px;

        & img,
        & video {
          height: 100%;
          width: 100%;
          height: 225px;
          border-radius: 6px;
          object-fit: cover;
          object-position: 50% 33%;
        }
      }

      .card-body {
        grid-row: 3/5;
        display: grid;
        row-gap: 0.5rem;
        align-content: space-evenly;
        padding: 0;
      }

      &:is(.nft-card) {
        grid-template-rows: 113px 113px 113px 1fr;
      }

      &:is(.has-author) {
        & .image-over {
          grid-row: 1 / 4;
        }


        & .seller {
          grid-row: 3/5;
        }

        .card-body {
          grid-row: 5/span 1;
        }
      }
    }
  }
}

.seller {
  position: relative;
  z-index: 3;

  &.has-frame {
    border-radius: 50%;
    transition: border 0.3s ease-in;
    border: 1.5px solid transparent;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: none;
      border-radius: 50%;
      z-index: 5;
      transition: all 0.3s linear;
    }

    &:not(.no-hover):hover {
      color: rgba(22, 39, 19, 0.15);
      border: 1.5px solid rgba(22, 39, 19, 0.15);

      &::before {
        box-shadow: inset 0px -2px 6px 8px rgba(22, 39, 19, 0.05),
          inset 0px 2px 6px 3px rgb(255 255 255 / 10%);
      }
    }
  }

  .avatar {
    position: relative;
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 999rem;
    z-index: 3;
  }
}

.item-seller {
  position: relative;

  .seller {
    display: grid;
    grid-template-columns: minmax(25px, max-content) max-content auto;
    align-self: center;
    margin: 0;
    width: 100%;
    gap: 0.25rem;

    .avatar {
      width: 25px;
      height: 25px;
    }

    span {
      align-self: center;
    }
  }



  .verified {
    position: relative;
    align-self: center;
    width: 1rem;
    max-width: 1rem;
    height: 1rem;
    max-height: 1rem;
  }
}

.activity-wrapper {
  &:first-child {
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(3, min-content);
    row-gap: 0.5rem;

    & > * {
      grid-column: 2;
    }

    & .image {
      grid-column: 1;
      grid-row: 1/-1;
    }

    & p {
      margin: 0;
    }
  }
}

.connect-wallet {
  display: block;
  margin: 0 auto 1rem;
  align-self: flex-start;
  text-align: center;
  font-weight: 500;
  padding: 0.75rem;
}

// Collection Detail Pages
.collection-details {
  & .featured-flag {
    grid-column: 1/-1;
    margin: 0;
    align-self: flex-start;
  }

  & .items {
    position: relative;
    z-index: 12;
  }

  & .banner {
    width: 100%;
    min-height: 150px;
    max-height: 50vh;
    height: 100%;
    object-fit: cover;

    & img {
      width: 100%;
      min-height: inherit;
      vertical-align: middle;
      object-fit: cover;
      object-position: center;
    }
  }

  & .details {
    display: grid;
    grid-template-areas: 'image'
      'title'
      'description'
      'socials'
      'analytics';
    gap: 1rem 2rem;
    margin-top: 1rem;
    justify-content: stretch;
    border-bottom: 1px solid #a8a8a8;
    padding: 0 1rem 1rem;

    & .image {
      grid-area: image;
      margin: 0 auto 1rem;
      width: 100%;
      height: auto;
    }

    & .title {
      font-size: 2.5rem;
      margin: 0;
      line-height: 1.1;

      &-wrapper {
        grid-area: title;
        display: grid;
        grid-template-columns: minmax(min-content, max-content) max-content;
        align-items: center;
        column-gap: 1rem;
        row-gap: 0.5rem;
      }
    }

    & p {
      grid-area: description;
      margin: 0;
      word-break: break-word;
      max-width: 550px;
    }

    @media (min-width: 767px) {
      text-align: left;
      grid-template-areas: 'title'
        'description'
        'socials'
        'analytics';

      &.has-image {
        grid-template-areas: 'image title'
          'image description'
          'image socials'
          'image analytics';
        grid-template-columns: minmax(min-content, 1fr) 1.2fr;
        grid-template-rows: minmax(0, min-content) minmax(0, max-content) 50px minmax(0, 1fr);
      }
    }
  }

  & .socials {
    grid-area: socials;
    display: flex;
    column-gap: 1rem;
    align-items: center;

    & h5 {
      margin: 0;
    }
  }

  & #Analytics {
    grid-area: analytics;
  }

  &#GroupedCollections {
    padding-bottom: 1rem;

    & .collection {

    }

    &.separate {
      padding-bottom: 0;

      & .banner {
        position: sticky;
        z-index: 1;
        top: 81px;

        @media (min-width: 767px) {
          top: 85px;
        }
      }

      & .details {
        display: block;
        position: relative;
        // background: rgb(255, 208, 0);
        margin: 0;
        padding: 0 0 0.5rem;
        top: -1rem;

        @media (min-width: 767px) {
          top: -2rem;
        }

        & .description {
          position: relative;
          z-index: 4;
          text-align: center;
          background: rgba(0, 0, 0, 0.8);
          color: #fff;
          padding: 1.5rem 2rem 1rem;
          border-radius: 1rem;
          margin: 0 auto;
          max-width: 90%;

          @media(min-width: 767px) {
            max-width: max-content;
          }

          & a {
            color: var(--secondary-color);
          }
        }

        & .save {
          position: relative;
          z-index: 5;
          margin: 0 auto;
          display: block;
          width: max-content;
          bottom: -1rem;
        }

        & .share {
          max-width: max-content;
          margin: 0.5rem auto;

          & .btn {
            padding: 0.5rem 1rem;
            background: #111;
            border: 2px solid transparent;
            transition: border 500ms;

            &:hover {
              border-color: #fff;
            }
          }
        }
      }

      & .collections {
        position: relative;
        z-index: 3;
        display: grid;
        margin-top: -1rem;
        // background: rgb(193, 100, 1);
        // border-left: 3px dashed rgb(255, 208, 0);
        // border-right: 3px dashed rgb(255, 208, 0);

        &::before {
          position: absolute;
          z-index: -1;
          top: 0;
          content: '';
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), transparent);
          width: 100%;
          height: 100%;
          max-height: 500px;
        }

        & h2 {
          text-align: center;
          grid-column: 1/-1;
        }

        & h3 {
          margin: 0 0 1rem;
        }

        @media (min-width: 767px) {
          grid-template-columns: 1fr minmax(0, 1fr);
          // gap: 1rem;
          margin-top: -2rem;
        }

        & .collection {
          padding: 0.5rem 0.75rem 1rem;
          border: 2px dashed rgb(170, 170, 170);
          background: rgba(255, 255, 255, 0.95);

          & .grid.items {
            background: inherit;
            padding: 0;
          }

          @media (max-width: 766px) {

            &:nth-child(2n+3) {
              background: rgba(234, 234, 234, 0.95);

              & .grid.items {
                background: inherit;
                padding: 0;
              }
            }
          }

          @media (min-width: 767px) {

            // Even because Title makes section so
            &:last-child:nth-child(even) {
              grid-column: span 2;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(7) {
              background: rgba(234, 234, 234, 0.95);

              & .grid.items {
                background: inherit;
                padding: 0;
              }
            }
          }

          & .items {
            justify-content: center;
          }

          & .items + .btn-block {
            @media (min-width:767px) {
              max-width: 75%;
              margin: 0 auto;
            }
          }
        }
      }
    }

    & .socials {
      display: flex;
      column-gap: 1rem;
      align-items: center;

      & h5 {
        margin: 0;
      }
    }
  }
}

.featured-flag {
  display: flex;
  position: relative;
  z-index: 10;
  align-content: center;
  gap: 0.25rem;
  background: rgb(58, 44, 195);
  background: linear-gradient(150deg, rgba(58, 44, 195, 0.75) 34%, rgba(230, 104, 203, 0.75) 64%);
  color: #fff;
  padding: 0.25rem 1rem;
  max-width: max-content;
  margin: 0 auto;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:after {
    height: 0;
    width: 0;
    top: 0;
    right: -1.2rem;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.5rem solid transparent;
    border-left: 1.2rem solid rgba(230, 104, 203, 0.75);

  }

  & svg {
    color: gold;
    font-size: 2.5rem;
    height: 100%;
  }

  & span {
    font-style: italic;
    text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.1rem;
    font-size: 1.75rem;
  }
}

.grid {
  display: grid;
  gap: 0.25rem;

  & .form-group {
    display: flex;
    gap: 0.5rem;
  }
}

.userControls {
  display: grid;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem auto;

  @media (min-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

.featured-flag {
  display: flex;
  position: relative;
  z-index: 10;
  align-content: center;
  gap: 0.25rem;
  background: rgb(58, 44, 195);
  background: linear-gradient(150deg, rgba(58, 44, 195, 0.75) 34%, rgba(230, 104, 203, 0.75) 64%);
  color: #fff;
  padding: 0.25rem 1rem;
  max-width: max-content;
  margin: 0 auto;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:after {
    height: 0;
    width: 0;
    top: 0;
    right: -1.2rem;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.5rem solid transparent;
    border-left: 1.2rem solid rgba(230, 104, 203, 0.75);

  }

  & svg {
    color: gold;
    font-size: 2.5rem;
    height: 100%;
  }

  & span {
    font-style: italic;
    text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.1rem;
    font-size: 1.75rem;
  }
}

.grid {
  display: grid;
  gap: 0.25rem;

  & .form-group {
    display: flex;
    gap: 0.5rem;
  }
}

.userControls {
  display: grid;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem auto;

  & .btn {
    width: 100%;
  }

  @media (min-width: 767px) {
    display: flex;
    gap: 1rem;

    & .btn {
      flex: 1 1 100%;
      width: auto;
    }
  }


}

#UpdateProfile {
  & .input-group {
    align-items: center;
    text-align: right;
    gap: 1rem;

    & label {
      width: 110px;
      background-color: transparent;
      text-align: right;
      font-weight: 600;

      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
      }
    }

    & textarea {
      height: auto;
    }
  }
}

.media.is-nervape {
  position: relative;

  & img {
    position: relative;
    z-index: 3;
    min-width: 150px;
    max-width: 100% !important;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    min-width: 150px;
    width: 100%;
    padding-bottom: 100%; // Trick height to parent width
    background-image: url('./assets/img/nervape-outline.png');
    opacity: 0.75;
    background-size: cover;
  }

  .nft-activity & {
    &::before {
      opacity: 1;
    }
  }
}

.main {
  background: var(--primary-bg-color);

  & article {
    position: relative;
    padding: 1.5rem 0;
    background: inherit;
  }

  & > article {
    padding: 0;

    & article {
      z-index: 1;
    }
  }
}

.bg-primary {
  background: var(--primary-bg-color) !important;
}

.info-group {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;

  & .icon {
    font-size: 2rem;
    color: var(--secondary-color);
  }
}

.dobBg {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 400px;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  text-align: center;
  align-self: start;
}
