.upload-wrapper {
  position: relative;
  min-height: 300px;

  & .preview-wrapper {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;

    &.has-file {
      border: 2px dotted rgb(13, 228, 13);
    }

    & .close-icon {
      position: absolute;
      display: grid;
      align-items: center;
      justify-items: center;
      top: 0.5rem;
      right: 0.5rem;
      font-size: 1.5rem;
      border: 1px solid red;
      width: 40px;
      height: 40px;
      border-radius: 999rem;
      color: #000;
      background: #fff;
      cursor: pointer;
    }

    & .preview-file {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      & .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        color: red;
      }

      & img,
      video,
      audio {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }

    &.banner {
      height: 300px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & .close-icon {
    top: 0.5rem;
    right: 0.5rem;
  }

  & .upload {
    position: relative;
    z-index: 10;
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    border: dotted 1px rgb(111, 52, 52);
    border-radius: 12px;
    padding: 2rem;
    height: 100%;
  }
}

#Create {

  &,
  & section {
    margin: 0 0 1rem;
  }

  & .banner {
    display: grid;
    align-items: center;
    position: sticky;
    top: 80px;
    height: 200px;

    @media(min-width:767px) {
      top: 85px;
    }

    & img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }

    & h1 {
      position: relative;
      z-index: 2;
      color: #fff;
    }
  }

  & textarea {
    resize: vertical;
  }

  #Chain {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  & .title {
    grid-column: 1/-1;
    text-align: center;
  }

  &--Collection {
    display: grid;
    z-index: 12;
    gap: 1rem;
    justify-items: center;

    & .collections {
      display: grid;
      gap: 1rem;
      width: 100%;
      justify-content: center;
      background: #fff;
      padding: 0.5rem;
      border-radius: 1rem;


      @media (min-width: 767px) {
        grid-template-columns: repeat(auto-fill, minmax(auto, 24%));
      }

      @media (min-width: 991px) {
        grid-template-columns: repeat(auto-fill, minmax(auto, 19%));
      }
    }

    &-modal {
      display: flex;
      flex-flow: column;
      gap: 1rem;

      @media (min-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
      }

      & .upload-wrapper {
        grid-column: 1;
        grid-row: 2;

        &.banner {
          grid-column: 1/span 2;
          grid-row: 1;
        }
      }

      & .footer {
        grid-column: 1/-1;

        & h6 {
          margin: 0 0 0.5rem;
        }
      }
    }

    .collection {
      display: grid;
      gap: 0.5rem;
      align-items: center;
      justify-items: center;
      grid-template-rows: minmax(150px, auto) auto;
      border: solid 1px #cacaca;
      border-radius: 1rem;
      cursor: pointer;
      text-align: center;
      overflow: hidden;
      padding: 0 0 0.5rem;
      min-width: 200px;
      max-width: 300px;
      min-height: 200px;
      margin: 0 auto;
      flex-basis: min-content;

      @media (min-width: 767px) {
        grid-template-rows: 200px auto;
      }

      &.active {
        border: solid 3px #a754da;
        background: #fafafa;
      }

      &:hover:not(.active) {
        background: #eee;
        border: 2px solid #b690cd;
      }

      & img {
        align-self: flex-start;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-bottom: 1px solid green;
      }

      & .content {
        display: grid;
        grid-template-rows: minmax(25px, max-content) max-content max-content;
        align-self: center;

        & .title {
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
    }
  }

  & .grid {
    display: grid;
    gap: 1rem 1.5rem;
    grid-template-columns: 1fr;

    & .upload-wrapper {
      grid-row: 1;

      &.has-cover {
        grid-row: 2;
      }

      &.cover {
        grid-row: 1;
      }
    }

    @media (min-width: 762px) {
      grid-template-columns: 1.5fr 1fr;

      & .title {
        grid-row: 1;
      }

      & .upload-wrapper {
        grid-column: 1;

        &.cover {
          grid-row: 1;
          grid-column: 1/3;
        }
      }

      & .details,
      & #StorageInfo {
        grid-column: 2;
      }
    }
  }

  &--Item {
    display: grid;
    z-index: 12;
    gap: 1rem;
    background: #fff;
    padding: 0.5rem;
    border-radius: 1rem;

    & .selected-collection {
      text-align: center;
      margin: 1.5rem 0 0.5rem;
      word-break: break-word;

      & strong {
        display: block;
      }
    }

    & .details {
      display: grid;
      gap: 0.75rem;
      grid-auto-rows: max-content;
    }

    & .disclaimer {
      grid-column: 1/-1;
      margin: 0;

      & p {
        margin: 0 0 1rem;
        font-style: italic;
      }
    }

    & .form-group.characteristic {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      align-items: center;
      gap: 0.75rem;

      & label {
        font-weight: bold;
      }
    }
  }

  &--traits {
    display: grid;
    grid-column: 1/-1;
    gap: 0.5rem;

    @media (min-width: 762px) {
      margin-top: 1rem;
    }

    & .traits-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 2.5rem;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }

    & .toggle-traits {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 2px dotted transparent;
      background: #ffa72ba1;
      padding: 0 0.5rem;
      line-height: 1;

      &.add {
        grid-column: span 2;
        background: #04db006f;
        color: #fff;
        justify-self: center;

        &.solo {
          grid-column: 1/-1;
        }
      }

      &:hover, &:active, &:focus {
        border-color: #fff;;
      }
    }
  }

  &--submit {
    grid-column: 1/-1;
    padding: 1rem;
    margin-top: 2rem;
  }

  & .form-group {
    margin: 0;
    display: inline-block;
  }

  .form-group {

    input,
    textarea,
    select,
    .custom-file-input,
    .custom-file-label,
    .custom-file-label::after {
      font-size: 1rem;
      font-weight: 500;
      color: var(--primary-p-color);
      padding: 15px;
      border: none;
      box-shadow: none;
      background-color: var(--white-color);
      appearance: auto;
      border-radius: 6px;
      box-shadow: inset 1px 1px 2px #BABECC, inset -5px -5px 10px #eeeeee73;
      transition: 0.5s;
    }

    input:focus,
    textarea:focus {
      background-color: var(--primary-b-color);
    }

    input,
    .custom-file-input,
    .custom-file-label,
    .custom-file-label::after {
      height: 50px;
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: "Upload";
    }

    .form-check-input {
      width: 1em;
      height: 1em;
      padding: 10px;
      background-color: var(--card-bg-color);
      border: 2px solid;

      &[type="radio"] {
        border-radius: .25em;
      }

      &:checked {
        background-color: var(--primary-bg-color);
        border-color: var(--primary-bg-color);

        &[type="radio"] {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
      }
    }
  }

  hr {
    border-top: 1px solid rgba(140, 140, 140, 0.2);
  }

  & .footer {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin-top: 1rem;

    hr {
      grid-column: 1/-1;
      border-top: 1px solid rgba(0, 216, 162, 0.4);
      margin: 0;
    }

    & .button-wrapper {
      display: grid;
      gap: 0.5rem;
      grid-column: 1/3;

      & .title {
        grid-column: 1/3;

        & h3,
        h4 {
          margin: 0;
        }
      }

      & .btn {
        justify-self: center;

        &:is(.remove) {
          grid-column: 1;
        }

        &:is(.add) {
          grid-column: 2;
        }

        &-block {}
      }
    }

    & .ckb-cost {
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      grid-column: 1/-1;
      gap: 0.75rem;
      margin: 0;

      & h4,
      & h5,
      & dt {
        margin: 0;
      }

      & small {
        font-size: 0.8rem;
        font-style: italic;
      }
    }


  }

}
