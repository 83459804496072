.author {
  position: relative;
  z-index: 10;
  // grid-template-columns: minmax(25px, max-content) max-content auto;
  align-self: center;
  margin: 0;
  width: 100%;
  // max-height: 50px;
  color: #000 !important;
  max-width: 350px;

  &.has-image {
    display: flex;
    gap: 0.25rem;

    & .author-name {
      margin-left: 0.75rem;
    }

  }

  &.center {
    justify-content: center;
  }

  &:hover {
    color: var(--primary-color) !important;
  }

  & .image-wrapper {
    position: relative;
    // flex: 1 1 0;

    & .image {
      border-radius: 100rem;
      width: 4rem;
      height: 4rem;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    & .verified {
      position: absolute;
      left: 70%;
      bottom: 0;
      z-index: 5;
    }
  }

  &-name {
    align-self: center;
    font-weight: 700;
    padding: 0;
    // flex: 2 1 auto;
  }

  &.collection {
    max-height: none;

    & .image {
      max-width: none;
    }

    & .author-name {
      display: none;
    }
  }

  &.lg {
    & .author-name {
      font-size: 1.2rem;
    }
  }

  &.xs {
    & .verified {
      // max-height: 12px;
    }
  }
}
