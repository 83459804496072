.ReactModal__Overlay {
  background-color: rgba(60, 57, 56, 0.85) !important;
  z-index: 99;

  & .content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    max-width: 90vw;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: #f1f1f1;
    color: #000;
    z-index: 100;
    padding: 1rem;

    & header {
      display: flex;
      justify-content: space-between;

      & h3 {
        margin: 0;
        height: 100%;
      }

      & .close {
        margin: 0.5rem 1.5rem 0 0;
        cursor: pointer;
      }
    }

    & .ctas {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-content: center;
      gap: 1rem;
    }
  }
}

.modal-footer {
  gap: 1rem;
  display: grid;

  & .warning {
    color: red;
    font-style: italic;
    font-weight: 900;
    margin: 0;
  }

  & .disclaimers {
    font-style: italic;

    & p {
      font-size: 0.8rem;
      margin: 0 0 0.25rem;

      @media (min-width: 767px) {
        font-size: 0.9rem;
      }
    }
  }
}

#market-modal {
  padding: 1rem 1.5rem !important;

  & .modal-header,
  & .modal-body,
  & .modal-footer {
    display: grid;
    padding: 0.5rem 0;
  }

  & .modal-header {
    display: block;
    padding: 0;

    & h2 {
      font-size: 2rem;
      margin: 0 0 1.5rem;
      line-height: 1;
      text-align: center;
    }

    & .market-type {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-self: center;
      gap: 1.5rem;
      margin: 1rem 0;

      & > div {
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 0.5rem;
        justify-items: center;
        max-width: 100%;
        height: 100%;
        padding: 2rem 1rem;
        text-align: center;
        border: solid 3px #cacaca;
        border-radius: 12px;
        cursor: pointer;

        &.active {
          border: solid 3px #57048a;
        }

        & svg {
          align-self: flex-end;
        }

        & h4 {
          margin: 0;
          line-height: 1;
          font-size: 1.4rem;
          max-width: 75%;
        }
      }
    }
  }

  & .modal-body {
    display: grid;
    gap: 1.5rem;

    @media (min-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }

    & .field {
      &.date-wrapper {
        grid-column: 1/-1;

        @media (min-width: 767px) {
          display: flex;
          gap: 1rem;
        }

        & .date {
          display: flex;
          gap: 0.5rem;
          flex-wrap: wrap;
          align-items: center;

          & label {
            flex: 1 1 50%
          }

          & .react-datepicker-wrapper {
            width: 100%;
          }
        }
      }
    }

    & .listing-details {
      grid-column: 1/-1;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      row-gap: 0.5rem;
      column-gap: 1.5rem;
      font-size: 0.9rem;

      @media (min-width: 767px) {
        font-size: 1rem;
      }

      & hr {
        grid-column: 1/-1;
        width: 100%;
        margin: 0.25rem auto;
        border-color: var(--primary-color);
      }

      & dt {
        margin: 0;
        justify-self: flex-end;
        text-align: right;
      }

      & dd {
        justify-self: flex-start;
      }
    }
  }

  & .modal-footer {
    grid-auto-rows: max-content;
    justify-content: stretch;

    & .button-wrapper {
      display: grid;
      gap: 0.5rem 1rem;

      @media (min-width: 767px) {
        grid-template-columns: 1fr 1fr;
      }

      & .btn {
        padding: 1rem;
        margin: 0;
      }
    }
  }
}

.web3-modal {
  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0.75rem;

    & hr {
      width: 100%;
      height: 0.5px;
      background: var(--primary-color);
      opacity: 0.65;
      margin: 0.5rem 0;
      border-radius: 999rem;
    }
  }

  & .connect-button {
    border-radius: 1rem;
    padding: 0.5rem 1.5rem;
    width: 66%;
    margin: 0;
    // background-color: #ccc;
    // border: 1px solid #999;

    &:first-child {
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.5rem;
    }

    & svg {
      max-width: 150px;
      max-height: 100%;
      width: 100%;
      height: 100%;
      // object-fit: contain;
    }

    &.joyid {
      font-size: 1.6rem;
      font-weight: 600;
      padding: 1rem 3rem;
      border: 2px dashed #8cDD00; // JoyID Green
      border-radius: 2rem;
      transition: all 500ms;
      width: 100%;

      & svg {
        width: 225px;
        max-width: 225px;
      }

      &:hover,
      &:focus {
        background-color: #8cDD001B;
        border: 2px dashed var(--primary-color);
      }
    }

    &.ckbull {
      background-color: #ccc;
      border: 1px solid #999;
      cursor: not-allowed;
      opacity: 0.55;

      & svg {
        max-height: 35px;
      }

      &:hover,
      &:focus {
        background-color: #ccc;
        border: 1px solid #999;
        outline: initial;
      }
    }
  }
}
