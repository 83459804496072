#activities {
  position: relative;
  z-index: 12;
  padding: 1rem 0;

  & .nft-list {
    @media (min-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  & .filters {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.75rem;

    @media (min-width: 767px) {
      position: sticky;
      z-index: 50;
      top: 96px;
    }

    & h2 {
      display: inline-block;
    }

    & button {
      background-color: #fff;
      color: #000;
      border-color: #000;

      &.active {
        color: var(--primary-color);
        border-color: var(--primary-color);
        font-weight: 600;
        border-width: 2px;
      }
    }
  }
}
