#ItemDetails {
  padding: 1rem;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    font-size: 2.75rem;
  }

  h2 {
    color: #000;
    font-size: 2rem;
    font-weight: 500;
    display: block;
  }

  h5 {
    font-weight: 500;
    font-size: 1.1rem;
  }

  hr {
    margin: 0;
  }

  & .item-thumb {
    width: 100%;
    margin: 0 auto;
    min-height: 350px;

    @media (min-width: 767px) {
      max-width: 75vw;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .nav-tabs {
    border: none;
  }

  .tab-content {
    & .single-tab-list {
      margin: 0.75rem 0;

      strong,
      a {
        color: var(--black-color);
        transition: 0.3s;
      }

      a {
        &:hover {
          color: var(--primary-color);
        }
      }
    }

    & .attributes {
      display: grid;
      gap: 1rem;

      @media (min-width: 767px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .item-owner {
    position: relative;
    gap: 0.5rem;
    min-height: 85px;
    border-bottom: 1px solid var(--primary-color);
    padding: 0.5rem;

    &:nth-child(2n+1) {
      background-color: #fff;
    }

    a {
      transition: color 0.3s;
      font-size: 1.1rem;
      font-weight: 700;
      color: #000;

      &:hover {
        color: var(--primary-color);
      }
    }

    & .author {
      padding-right: 0.33rem;
    }

    button {
      align-self: center;
    }

    & > .tab-pane:is(.active) {
      display: grid;
      gap: 1.2rem;
    }
  }

  #AuctionHub {
    color: var(--black-color);
    padding: 1rem;
    background: #fff;
    border-radius: 1rem;
    text-align: center;
    width: 100%;
    // justify-items: center;

    & .nav {
      justify-self: center;

      & a {
        color: inherit;
        font-weight: 500;

        &.active {
          color: var(--primary-color);
          font-weight: 700;
        }
      }
    }

    > div {
      display: inline-block;

      > span {
        display: block;
        text-align: center;
      }
    }

    & .clock-wrapper {
      display: none;
      text-transform: uppercase;

      &.active {
        display: block;
      }
    }

    & .countdown {
      display: grid;
      grid-auto-flow: column;
      margin-top: 1rem;
      background: #eee;

      &.no-clock {
        & u {
          display: block;
          margin-bottom: 0.25rem;
        }

        & p {
          text-transform: none;
          padding: 0 1rem;
          inline-size: 75%;
          overflow-wrap: break-word;
          margin: 0 auto;
        }
      }
    }

    .countdown-container {
      display: grid;
      justify-items: center;

      .countdown-heading {
        font-size: 0.75rem;
        font-weight: 500;
      }

      .countdown-value {
        font-family: var(--primary-font);
        font-weight: 600;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);

        @media (max-width: 575px) {
          font-size: 25px;
        }
      }
    }

    & .bid {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      margin-top: 1rem;
      flex-flow: row wrap;
    }

    & hr {
      background-color: var(--primary-color);
      margin: 0.5rem 0;
    }

    & .end-auction {
      margin-top: 1rem;
      width: 100%;
      padding: 1rem 0.5rem;
    }
  }

  li {
    margin: 0;

    a {
      position: relative;
      display: inline-block;
      margin: 0 10px;
      padding: 10px 0;

      &::after {
        position: absolute;
        content: '';
        height: 1px;
        width: 0;
        bottom: -1px;
        left: 0;
        background-color: var(--primary-color);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease 0s;
      }

      &.active {
        h5 {
          color: var(--primary-color);
          font-weight: 600;
        }

        &::after {
          width: 100%;
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &:first-of-type {
      a {
        margin-left: 0;
      }
    }
  }

  & .item-description {
    margin: 0;
  }

  & .royalties {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;

    & > * {
      flex: 1 0 0;
    }
  }

  & .sales-area {
    padding: 0;

    & hr {
      background-color: var(--primary-color);
      margin: 0.5rem 0 1rem;

      @media (min-width: 767px) {
        max-width: 50%;
      }
    }

    h3,
    h4 {
      font-weight: 400;
      font-size: 1.5rem;
      margin: 0.5rem 0;

      span {
        margin-right: 1rem;
      }
    }

    & h3 {
      margin-bottom: 1rem;
    }

    & .card-wrapper,
    & .buy-actions {
      display: flex;
      gap: 0.5rem;
      flex-flow: row wrap;

      & > * {
        // flex: 1 0 100%;
        flex: 1 1 100%;


        @media (min-width: 767px) {
          flex: 1 1 48%;
        }
      }

      & .connect-wallet {}
    }
  }

  & .connect-wallet {
    align-self: flex-start;
    text-align: center;
    font-weight: 500;
    padding: 0.75rem;
  }

  & .social-media {
    display: flex;
    align-self: center;
    gap: 1rem;
    border-top: 1px solid var(--secondary-color);
    border-bottom: 1px solid var(--secondary-color);
    max-width: 125px;
    padding: 0.5rem 0;

    @media (min-width: 767px) {
      margin: 0.5rem 0;
    }
  }

  & .owner-cards {
    gap: 1rem;
    align-items: stretch;

    & .card {
      min-height: 85px;
      height: 100%;
      justify-content: center;
    }
  }

  & .user-interaction {
    display: flex;
    gap: 0.5rem 1%;
    flex-flow: row wrap;
    align-content: center;
    align-self: start;

    > button {
      flex-basis: 100%;
      align-self: stretch;
      margin: 0;
      padding: 1.2rem 2.2rem;
    }
  }

}


/* Structure */
#ItemDetails {
  display: grid;
  gap: 1rem;

  & .item-thumb {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .title-wrapper {
    display: grid;
    align-items: center;
    gap: 1rem;
    grid-row: 1;
    grid-column: 1;
    grid-template-columns: 1fr max-content;

    & .title {
      word-break: break-all;
    }

    & .save {
      justify-self: flex-start;
    }

    @media (min-width:767px) {
      gap: 2%;

      & .save {
        justify-self: flex-end;
      }
    }
  }

  .item-thumb {
    grid-row: 2;
    grid-column: 1;
  }

  & .item-info-list {
    grid-row: 3;
    display: grid;
    align-items: center;
    gap: 1rem;

    & > div:not(.item-description) {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    & dt {
      margin: 0;
    }
  }

  #AuctionHub {
    display: grid;
    grid-row: 2;
    grid-column: 1;
  }

  & .social-media {
    grid-row: 5;
    grid-column: 1;
  }

  & .sales-area {
    grid-row: 4;
    grid-column: 1;
  }

  & .user-interaction {
    grid-row: 6;
    grid-column: 1;
  }

  .item-owner {
    display: grid;
    grid-template-columns: minmax(65px, max-content) minmax(auto, 275px);
    grid-template-rows: auto auto;

    & h6 {
      grid-column: 2;
    }

    & .author {
      grid-row: 1/-1;
    }

    & .description {
      grid-column: 2;
    }

    button {
      grid-column: 1/-1;
    }
  }

  & .owner-cards {
    display: grid;
    grid-template-columns: 1fr;
  }

  // Desktop grid system
  @media (min-width: 768px) {
    gap: 1rem 1.5rem;
    grid-template-columns: 1fr 1.2fr;
    grid-template-rows: repeat(8, auto);

    .item-thumb {
      grid-row: 1/span 5;
      grid-column: 1;
    }

    .nav-tabs {
      grid-column: 1;
      grid-row: 6;
    }

    .tab-content {
      grid-column: 1;
      grid-row: 6;
      margin-top: 4rem;
    }

    .item-owner {
      & .description {
        // align-self: flex-end;
      }

      &.buy {
        & .btn {
          grid-row: 1/-1;
        }
      }

      & button {
        grid-column: 3;
      }
    }

    .title-wrapper {
      grid-row: 1;
      grid-column: 2;
    }

    .item-info-list {
      grid-row: 2;
      grid-column: 2;
    }

    .social-media {
      grid-column: 2;
      grid-row: 3;
    }

    .royalties {
      grid-row: 4;
      grid-column: 2;
    }

    .owner-cards {
      grid-column: 2;
      grid-row: 5;
      align-content: flex-start;
      grid-template-columns: 1fr 1fr;
    }

    .user-interaction {
      grid-column: 1/-1;
      grid-row: 7;

      > button {
        flex: 1 1 48%;
      }
    }

    .sales-area {
      grid-column: 2;
      grid-row: 6;
      align-content: flex-start;

      .countdown {
        justify-content: space-evenly;
      }
    }
  }
}
