.avatar-verification {
  position: absolute;
  left: 4rem;
  top: 1rem;
}

.owner-verification {
  position: absolute;
  left: 1.65rem;
  width: 1rem;
}

.seller-owner-verification {
  position: absolute;
  left: 4rem;
}

.nft-info-owner-verification {
  width: 0.75rem;
  position: absolute;
  top: 1rem;
  left: 0.75rem;
}

.activity-verification {
  position: absolute;
  left: 4.5rem;
}
