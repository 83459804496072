#Transfer {
  display: grid;
  gap: 1rem;
  padding: 0 2rem 3rem;

  --tertiary-color: #00CC9B; // Godwoken green

  & .item-details {
    display: grid;
    grid-template-columns: 1fr;

    & > span {
      padding: 0.5rem 2rem;
      overflow-wrap: anywhere;
      word-break: break-word;
      text-align: center;
    }

    @media (min-width: 767px) {
      &.has-tokenId {
        grid-template-columns: 1fr 1fr;
      }

      grid-template-rows: min-content min-content;
    }

    justify-items: center;

    & .collection-address {
      line-break: anywhere;
    }

    @media (min-width: 767px) {
      & h4 {
        grid-row: 1;
      }

      & span {
        grid-row: 2;
      }
    }
  }

  & hr {
    margin: 1rem auto;
    width: 33%;
    border-top: 3px solid var(--tertiary-color);
  }

  & form {
    display: grid;
    gap: 1rem;

    @media (min-width: 767px) {
      grid-template-columns: 4fr 1fr;
    }

    & .button-wrapper {
      grid-column: 1/-1;
      display: grid;

      gap: 1rem;

      @media (min-width: 767px) {
        grid-template-columns: 1fr 1fr;
      }

      & .btn {
        padding: 0.75rem 2rem;
      }

      &.loading {
        & .spinner {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 1.5rem;
            height: 1.5rem;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 4px solid transparent;
            border-top-color: #ffffff;
            border-radius: 50%;
            animation: button-loading-spinner 650ms linear infinite;
          }

          & span {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
  }
}
