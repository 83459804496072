#header {
  position: sticky;
  top: 0;
  z-index: 1050;
  padding: 0.5rem 0.25rem;
  transition: all .2s ease-in;
  background-color: var(--header-bg-color);

  @media(min-width: 767px) {
    padding: 0.2rem 0.5rem 0.5rem;
  }

  & #AlertBar {
    display: none;
    position: fixed;
    z-index: 150;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;

    &.show {
      display: block;
    }
  }

  #page-home & {
    position: fixed;
    width: 100%;
    background-color: transparent;
    // background-color: rgba(47, 0, 73, 0.6);

    &.sticky {
      background-color: var(--header-bg-color);
    }
  }

  & .container {
    gap: 0.5rem;
  }

  & button {
    border: none;
  }

  & .logo {
    padding-top: 11px;

    @media(min-width: 767px) {
      max-width: 250px;
    }
  }

  & .navbar-expand {
    justify-content: space-between;
  }

  & .nav-item {
    margin: 0;

    &.dropdown {
      & button {
        margin-top: 1rem;
      }

      & .nav-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: center;
        line-height: 1;
        width: max-content;
        gap: 1rem;
        padding: 0;

        & > svg {
          padding-bottom: 0.5rem;
        }
      }

      & .dropdown-menu {
        min-width: max-content;
        display: block;
        opacity: 0;
        visibility: hidden;
        height: 0;
        z-index: -1;
        transition: all 400ms;
        position: relative;
        top: -10%;
        background: #fff;
        padding: 0;

        @media (max-width: 766px) {
          z-index: 50;
          height: auto;
          top: 5px;
          opacity: 1;
          transition: all 400ms;
          padding: 1.25rem 4rem 0.5rem 2rem;
          visibility: visible;
          margin-bottom: 0.5rem;
        }

        @media (min-width: 767px) {
          position: absolute;
        }

        & .nav-link {
          color: #000;
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 0.5rem;

          & > span {
            padding-top: 0.5rem;
          }
        }

        &.show {
          z-index: 50;
          height: auto;
          top: 5px;
          opacity: 1;
          transition: all 400ms;
          padding: 1.25rem 4rem 0.5rem 2rem;
          visibility: visible;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  & .nav-link {
    border: none;
    color: #fff;
    transition: border 500ms;
    background: none;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      font-weight: 600;
    }

    & > span {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid transparent;
    }

    &.active > span {
      border-color: var(--secondary-color);
    }

    &:hover > span {
      border-color: var(--tertiary-color);
    }
  }

  & .modal {
    & .row {
      gap: 1rem;
    }

    & .nav-link {
      color: #000;
      text-align: left;
    }
  }

  & .navbar {
    display: grid;
    grid-template-columns: minmax(33%, 200px) 1fr auto;
    justify-items: center;

    &:not(.inner) {
      width: 100%;
      padding: 0.5rem 0.75rem 0.5rem 1rem;
    }

    &.inner {
      background-color: transparent !important;
    }

    #page-home & {
      background-color: transparent;
    }

    .navbar-nav {
      display: flex;
      gap: 1rem;
      background: transparent;

      &.main {
        display: none;

        @media (min-width: 767px) {
          display: flex;
        }
      }
    }

    & .btn {
      padding: 0.15rem;

      & svg {
        line-height: 0;
        margin: 0;
      }
    }

    & .toggle {
      position: relative;
      top: 2px;
      color: #fff;

      & svg {
        font-size: 2rem;
      }
    }

    & .search {
      display: none;

      @media (min-width: 767px) {
        display: inline-block;
      }
    }
  }

  & #menu {
    & .search {
      display: inline-block;
      padding: 0;
      margin-top: 1rem;
    }

    & .modal-header {
      justify-content: space-between;
    }
  }

  & #UserPopover {
    position: relative;
    transition: all 5s;

    &.has-unread::before {
      content: '';
      position: absolute;
      z-index: 2;
      top: -0.25rem;
      right: -0.25rem;
      background: #d10a06;
      font-size: 0.85rem;
      width: 18px;
      height: 18px;
      color: #fff;
      border-radius: 20rem;
      display: grid;
      align-items: center;
      justify-items: center;
    }

    & .toggle {
      background: color-mix(in srgb, var(--primary-color) 75%, transparent);
      border-radius: 0.5rem;
      padding: 0;
      padding: 0.33rem;
      // max-width: 50px;

      & .icon {
        width: 2.5rem;
        height: 2.5rem;
        max-width: initial;
        max-height: initial;
      }

      & .address {
        display: none;
        color: var(--top-nav-item-color);
      }

      @media (min-width: 767px) {
        background: var(--primary-color);
        padding: 0.5rem 1rem;
        box-shadow: 2px 2px 1px var(--secondary-color);
        border-top: 2px solid color-mix(in srgb, var(--tertiary-color) 33%, transparent);
        border-left: 2px solid color-mix(in srgb, var(--tertiary-color) 33%, transparent);

        & svg {
          margin-right: 0.5rem;
        }

        & .address {
          display: inline;
        }
      }
    }

    & #UserActions {
      inset: unset;
      color: #000;
      background: #fff;
      width: 300px;
      padding: 1rem;
      border: 3px solid var(--primary-color);
      border-top: none;
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0px 3px 4px 3px rgb(0 0 0 / 15%);
      transition: opacity 850ms;
      opacity: 0;

      &:popover-open {
        opacity: 1;
      }

      & hr {
        margin: 0.75rem 0;
      }

      & .title {
        & h3 {
          margin: 0;
        }

        & .copy {
          position: absolute;
          right: 1rem;
          top: 1rem;
          background: transparent;
        }
      }

      & .balances {
        display: grid;
        margin: 0.5rem 0;
        gap: 0.5rem;

        & span {
          display: grid;
          grid-template-columns: 1fr minmax(50px, auto) auto;
          gap: 0.5rem;

          & strong {
            text-align: right;
          }
        }

        & .nft-standard-logo {
          max-width: 25px;
        }
      }

      & .actions {
        display: grid;
        gap: 1rem;

        & .top,
        & .bottom {
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          gap: 1rem;

          & > * {
            flex: 1 1 100%;
          }
        }

        & .btn {
          --btn-border-radius: 0.5rem;
          padding: 0.5rem;
        }

        & .unread {
          position: absolute;
          top: -0.25rem;
          right: -0.25rem;
          background: #d10a06;
          font-size: 0.85rem;
          width: 18px;
          height: 18px;
          color: #fff;
          border-radius: 20rem;
          display: grid;
          align-items: center;
          justify-items: center;
        }

        & .create {
          color: var(--tertiary-color);
          transition: color 150ms ease-in;

          &:hover {
            color: color-mix(in srgb, var(--tertiary-color) 80%, var(--primary-color));
          }
        }

        & .account {
          transition: color 150ms ease-in;

          &:hover {
            color: color-mix(in srgb, var(--primary-color) 80%, var(--secondary-color));
          }
        }

        & .notifications {
          position: relative;
          color: #000;

          transition: color 150ms ease-in;

          &:hover {
            color: #333;
          }
        }

        & .update {
          background-color: var(--secondary-color);
          border: none;

          &:hover {
            background-color: var(--primary-color);
          }
        }

        & .logout {}
      }
    }
  }

  @media screen and (min-width: 767px) {
    z-index: 100;

    & nav {
      .navbar-nav {
        align-items: center;

        &.main {
          display: flex;
          margin: 0 auto;
        }


        &.actions {
          gap: 1rem;
        }
      }

      & .btn.toggle {
        display: none;
      }

      & .nav-item {
        &.dropdown {
          &:hover .dropdown-menu {
            z-index: 50;
            height: auto;
            top: 100%;
            opacity: 1;
            visibility: visible;
            transition: all 400ms;
            padding: 0.5rem 1.5rem 0;
          }

          & button {
            margin-top: 0.6rem;
          }
        }
      }
    }
  }

  & #menu.show {
    & .navbar-nav {
      display: flex;
    }
  }
}
