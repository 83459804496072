#AdminDashboard {
  padding: 1rem 0 2rem;

  & .alert {
    position: fixed;
    z-index: 200;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 1.5rem;
  }

  & h1 {
    text-align: center;
    font-size: 3.25rem;
  }

  & h2, h3 {
    margin: 0;
  }

  & h2 {
    margin-bottom: 1rem;
  }

  & hr {
    max-width: 50%;
    margin: 1rem 0 2rem;
    border-top-color: #444;
    border-width: 3px;
  }

  & .action-group {
    display: grid;
    // grid-template-areas: 'label';
    gap: 1rem;
    align-items: flex-end;
    margin-bottom: 1rem;

    & h2 {
      grid-column: 1/-1;
      margin: 0;
      font-size: 2.5rem;
    }

    & button {
      padding: 0.5rem 1rem;
      font-weight: 400;
      max-width: 200px;
    }
  }
}
