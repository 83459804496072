.breadcrumb-area {
  position: relative;
  z-index: 11;
  display: flex;
  height: 200px;
  background: rgba(0, 0, 0, 0) url("../../assets/img/hero.webp") no-repeat scroll center center / cover;
  align-items: center;
  padding: 0 0.75rem;

  &::after {
    opacity: 0.1 !important;
  }

  & h1 {
    color: var(--secondary-t-color);
    font-size: 2.5rem;

    @media(min-width: 767px) {
      font-size: 3.25rem;
    }
  }

  &.bg-overlay::after {
    opacity: 0.89;
  }

  .breadcrumb-item {
    font-size: 1rem;
    font-weight: 600;
    margin: 0.3rem 0;

    a {
      &:not(.btn) {
        font-weight: 600;
        color: var(--white-color) !important;
      }

      &:hover {
        color: var(--white-color);
      }
    }

    &.active {
      color: var(--white-color) !important;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  .breadcrumb-content {
    position: relative;
    z-index: 10;

    h2 {
      font-weight: var(--h1-weight);
      text-shadow: 2px 2px rgba(0, 0, 0, .65);
    }

    .breadcrumb {
      background-color: transparent;
      margin: 0;

      .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
        color: var(--white-color);
      }
    }
  }

  &.has-background {
    &::after {
      opacity: 0.45;
    }

    & .background-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &.tall {
    height: auto;
    max-height: 350px;
  }

  & .save {
    display: block;
    margin: 1rem auto 0;
    max-width: 125px;
  }
}
