#Search {
  // margin-bottom: 2rem;
  color: #000;

  &Input {
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    gap: 0.5rem;
    align-items: center;

    &:not(.hasSubmit) input {
      grid-column: 1/-1;
    }
  }

  & .sort {
    grid-row: 1;
    grid-column: 2;
  }

  & .refine {
    grid-column: 1;
    grid-row: 1;

    @media (min-width: 767px) {
      grid-row: 1/-1;
    }
  }

  &Topbar,
  &Sidebar {
    display: grid;
    gap: 1rem;
    background: #ffffff;
    padding: 0.6rem 1.2rem;
    height: 100%;
    width: 100%;
  }

  &Topbar {
    justify-content: space-between;

    & .form-group {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
      text-transform: uppercase;

      & label {
        font-weight: bold;
      }

      & select {
        width: max-content;
      }
    }

    @media (min-width: 767px) {
      grid-auto-flow: column;
    }
  }

  &Sidebar {
    flex-direction: column;
    align-self: flex-start;
    gap: 1rem;

    & h4 {
      font-weight: 700;
    }

    & .flex {
      display: flex;
      gap: 0.5rem;

      & label {
        letter-spacing: 0.075rem;
      }

      &.child {
        margin: 0.25rem 0rem 0.25rem 1rem;
      }
    }
  }

  & .form-group {
    margin: 0;

    & .ranges {
      display: grid;
      align-items: center;
      gap: 0.5rem;

      & hr {
        border: 1px solid black;
        margin: 0 auto;
        max-width: 1rem;
        width: 100%
      }

      @media (min-width: 768px) {}

      @media (min-width: 992px) {
        grid-template-columns: minmax(50px, 46%) minmax(2px, 2%) minmax(50px, 46%);
      }

      @media (min-width: 1200px) {}
    }
  }

  & .search-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 1rem;
    align-items: flex-start;
    justify-items: center;
    margin-top: 2rem;
    padding: 0 1rem;

    & h2 {
      font-size: 2.2rem;
      margin: 0;
    }

    & h3 {
      font-size: 1.75rem;
      margin: 0 0 0.75rem;
    }

    & h2,
    h3 {
      color: inherit;
    }

    & form {
      gap: 0.5rem;

      & button {
        width: 100%;
        padding: 1rem !important;

      }
    }

    // .search-wrapper
    @media (min-width: 767px) {
      grid-template-columns: 1fr 3fr;

      & form {
        display: grid !important;
        grid-template-columns: 5fr 1fr;

        & button {
          padding: 0 !important;
        }
      }
    }

    @media (min-width: 991px) {
      padding: 0 2rem;
    }

  }

  & .search-grid {
    position: relative;
    z-index: 12;
    grid-column: 1/-1;
    gap: 1rem;
    width: 100%;
    padding-bottom: 1rem;

    @media (min-width: 768px) {
      grid-column: 2;
    }

    & .no-items {
      grid-column: span 3;
    }

    &.items {
      display: grid;

      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
      }

      @media (min-width: 1150px) {
        grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
      }

      @media (min-width: 1450px) {
        grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
      }
    }
  }

  & .radio-wrapper {
    position: relative;

    & input {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:checked + .icon {
        border-color: var(--primary-color);
      }
    }

    & .icon {
      position: relative;
      z-index: 1;
      top: 0;
      left: 0;
      border-radius: 0.5rem;
      padding: 0.05rem;
      border: 2px solid transparent;
      // width: 100%;
      // height: 100%;
    }

    &.disabled {
      display: none
    }
  }

  & details {
    position: sticky;
    z-index: 50;
    background: #fff;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    top: 100px;
    width: 100%;
    border-radius: 0.75rem;
    box-shadow: 1px 1px #000;

    & summary {
      display: flex;
      justify-content: space-between;
      background: #fff;
      padding-top: 0.25rem;
      list-style: none;
      width: 100%;

      &::-webkit-details-marker {
        display: none; // Remove arrow
      }

      @media (max-width: 768px) {
        padding: 1.5rem 0.5rem;
      }
    }

    &[open] {
      z-index: 51;

      @media (max-width:768px) {
        z-index: 101;
      }

      & summary {
        display: none;

        @media (max-width:768px) {
          display: flex;
          position: fixed;
          max-width: 90vw;
          z-index: 101;
          top: 5rem;
          left: 1rem;
          right: 0;
        }

        & .arrow {
          transform: rotate(90deg);
        }
      }

      @media (max-width:768px) {
        & > div {
          position: fixed;
          top: 4.75rem;
          left: 0;
          right: 0;
          z-index: 99;
          overflow: scroll;
          align-content: flex-start;
          padding-top: 5rem;
          padding-right: 5rem;
          padding-bottom: 7rem;
        }
      }
    }
  }
}
