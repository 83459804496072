.save {
  cursor: pointer;
  color: var(--secondary-color);
  background: rgba(225,225,225,0.85);
  // box-shadow: 1px 1px 3px 0px #00000026, inset 1px 1px 3px 0px #00000026;
  padding: 0.15rem 0.45rem;
  border-radius: 1rem;
  border: 1px solid var(--secondary-color);
  transition: border-color 500ms;

  &:hover {
    border-color: var(--primary-color);
  }

  & span {
    font-weight: 500;
    margin-left: 0.25rem;
  }
}
