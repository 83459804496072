.author-profile {
  margin-top: 1rem;
  // text-align: center;

  .banner {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 250px;
    margin: 0 auto;
    border-radius: 0.25rem;
  }

  & header .content {
    display: grid;
    position: relative;

    & .title-wrapper {
      display: grid;
      gap: 0.5rem;
      margin-top: -3rem;
      justify-items: center;

      @media (min-width: 767px) {
        // margin-top: 12rem;
      }

      & img {
        max-width: 250px;
        max-height: 125px;
      }

      & .title {
        position: relative;
        z-index: 2;
        margin: 0;
      }
    }

    & .body {
      position: relative;
      z-index: 2;
      display: grid;
      justify-items: center;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      & .content {
        max-width: 500px;
      }

      & .addresses {
        display: grid;
        justify-content: center;
        width: 100%;
        gap: 1rem;

        @media (min-width: 767px) {
          grid-template-columns: max-content max-content;
          gap: 2rem;
        }

        & label {
          margin-bottom: 0.5rem;
        }

        & .input-group {
          // max-width: 275px;
          margin: 0 auto;

          & input {
            // width: max-content;
            background-color: var(--white-color);
            outline: none;
            border-radius: 0.5rem 0 0 0.5rem;
          }

          & button {
            border: 1px dotted rgba(0, 0, 0, 0.5);
            border-radius: 0 0.5rem 0.5rem 0;
          }
        }
      }
    }
  }

  & .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 1rem;
  }

  & .container {
    & .explore-menu .btn {
      background: inherit;
      color: var(--black-color);
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      outline: none;
      position: relative;
      box-shadow: none;

      &::after {
        position: absolute;
        content: '';
        height: 2px;
        width: 0;
        bottom: 5px;
        left: 50%;
        background-color: var(--primary-color);
        transform: translateX(-50%);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease 0s;
      }

      &.active,
      &:hover {
        color: var(--primary-color);

        &::after {
          width: 60%;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}



.profilePic {
  justify-content: center;
  // display: inline-block;
  // position: relative;
  // z-index: 2;
  // width: 100px;
  // height: 100px;
  // margin: 0 auto;
  // overflow: hidden;

  // & .avatar {
  //   height: 100%;
  //   width: 100%;
  //   border-radius: 1000rem;
  //   object-fit: cover;
  //   // object-view-box: inset(40% 20% 20% 40%); Adjust zoom level
  // }
}
