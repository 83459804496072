.author-area {
  position: relative;
  z-index: 12;

  & .authors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 auto;

    @media (max-width: 766px) {
      justify-content: center;
    }

    & .author {
      max-width: 250px;
    }
  }

  .card {
    .image-over {
      position: relative;
      overflow: visible;
      z-index: 1;

      .author {
        display: block;
        position: absolute;
        top: calc(100% - 4rem);
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;

        .author-thumb {
          position: relative;
          border: 3px solid rgba(255, 255, 255, 0.12);
          border-radius: 50%;
          box-shadow: inset 0 0 0 2px rgba(9, 8, 13, 0.15);
          margin: 0 auto;

          &::before,
          &::after {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 50%;
            transition: all 0.2s ease 0s;
          }

          &::before {
            content: "";
            top: 0;
            width: calc(7rem - 6px);
            height: calc(7rem - 6px);
            opacity: 0;
            box-shadow: 0 0 0 2px rgba(9, 8, 13, 0.15);
            transform: scale(.8);
          }

          &::after {
            content: "";
            top: 5px;
            width: calc(7rem - 16px);
            height: calc(7rem - 16px);
            background-color: rgba(9, 8, 13, 0.15);
            box-shadow: 0 0 0 0 transparent;
          }

          img {
            height: calc(7rem - 16px);
            width: calc(7rem - 16px);
            margin: 5px;
          }
        }
      }
    }

    .card-body {
      .input-group {
        position: relative;
        z-index: 1;

        .form-control {
          border-radius: 6px;
          background-color: var(--white-color);
          border: 1px solid var(--primary-b-color);
          height: 40px;
          padding: 1.5rem 1rem;
          box-shadow: inset 1px 1px 2px #babecc, inset -5px -5px 10px rgb(238 238 238 / 45%);
          outline: none;

          &:focus {
            outline: none;
            border-color: white;
            background-color: var(--primary-b-color);
          }

          &:read-only,
          &[readonly] {
            box-shadow: none;

            &:focus {
              background-color: var(--white-color);
              border: 1px solid var(--primary-b-color);
            }
          }
        }

        .input-group-append {
          position: absolute;
          height: 100%;
          top: 0;
          right: 0;
          border-radius: 100px;
          overflow: hidden;

          button {
            margin-right: 5px;
            padding: 12px 16px;
            background-color: transparent;
            color: var(--white-color);
            border: 0;
            outline: none;
          }
        }
      }

      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-top: 1px dashed rgba(255, 255, 255, 0.15);
      }
    }

    &:hover {
      transform: initial;

      .image-over {
        .author {
          .author-thumb {
            &::before {
              opacity: 1;
              transform: scale(1);
            }

            &::after {
              height: calc(5rem + 4px);
              width: calc(5rem + 4px);
              top: -5px;
              left: -5px;
              background-color: transparent;
              box-shadow: 0 5px 10px rgba(14, 49, 139, 0.5);
            }
          }
        }
      }
    }
  }
}
