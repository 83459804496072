#UpdateCollection {
  h1 {
    font-size: 3.2rem;
    text-align: center;
    grid-column: 1/-1;
    margin: 1rem 0;
  }

  & .wrapper {
    position: relative;
    padding: 8rem 0 0;

    &.has-image {
      padding: 0;

      & img {
        width: 100%;
        height: 100;
        object-fit: contain;
      }
    }
  }

  & .banner {
    width: 100%;
    max-height: 50vh;
    object-fit: cover;
    min-width: 90vw;
  }

  & .image-upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    background-color: #fff;

    & input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      opacity: 0;
      height: 100%;
      cursor: inherit;
    }

    & .btn {
      display: block;
      max-width: max-content;
      font-size: 1.1rem;
      font-weight: 500;
      box-shadow: 1px 1px 5px 3px #0000008c;
      cursor: inherit;
      background-color: #fff;
      margin: 1rem auto;
    }
  }

  & .featured-flag {
    margin-bottom: 2rem;
  }

  & .userControls {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 2rem auto;
  }

  & .details {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 0.25rem;
    justify-content: stretch;

    & .view {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    & .image {
      margin: 0 auto 1rem;
    }

    & .title {
      font-size: 3.2rem;
      margin: 0;

      &-wrapper {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        column-gap: 1rem;
        justify-self: flex-start;
      }
    }

    & p {
      margin: 0;
    }

    & .footer {
      grid-column: 1/-1;
      margin: 1rem 0;
      display: grid;
      row-gap: 1rem;
    }

    & .submit {}

    @media (min-width: 767px) {
      text-align: left;

      &.has-image {
        grid-template-columns: 1fr 1fr;
        justify-content: center;

        & > * {
          grid-column: 2;
        }

        & .image {
          grid-row: 1/5;
          grid-column: 1;
        }
      }
    }
  }

  & .socials {
    display: flex;
    column-gap: 1rem;
    align-items: center;

    & h5 {
      margin: 0;
    }
  }

  // &.version3 {
  //   position: relative;

  //   & .banner {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     z-index: -1;
  //   }

  //   & .details {
  //     margin: 0;
  //     padding-top: 4rem;
  //   }
  // }


}
